'use client';

import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';

import Label from './Label';
import If from '~/core/ui/If';

type Props = React.InputHTMLAttributes<unknown> & {
  iconsRight?: () => JSX.Element | null;
};

const Hint: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <span
      className={`block pl-1 text-xs
        font-normal leading-tight text-gray-500 dark:text-gray-400`}
    >
      {children}
    </span>
  );
};

const Input = forwardRef<React.ElementRef<'input'>, Props>(function TextFieldInputComponent(
  { className, children, defaultValue, iconsRight, ...props },
  ref,
) {
  return (
    <div
      className={classNames(
        `active-within:ring-1 relative flex h-10 w-full items-center
        rounded-md border border-neutral-200 bg-white
        font-medium text-gray-800
        shadow-sm
        transition-all focus:outline-none dark:border-none dark:border-gray-600 dark:bg-black-200 dark:text-white lg:text-sm`,
        className,
        {
          [`cursor-not-allowed !bg-gray-100 hover:bg-gray-100 disabled:bg-gray-100 dark:!bg-black-400 `]:
            props.disabled,
          'border-red-500': props['aria-invalid'],
        },
      )}
    >
      <If condition={children}>
        <span className={'flex pl-2.5'}>{children}</span>
      </If>

      <input
        {...props}
        className={classNames(
          `h-10 flex-1 rounded-md bg-transparent px-3 py-2 focus:outline-none disabled:cursor-not-allowed disabled:opacity-30 dark:border-gray-600 dark:bg-black-200`,
          className,
          {
            ['pr-8']: iconsRight,
            'focus:ring-1 focus:ring-red-500': props['aria-invalid'],
            'focus:ring-1 focus:ring-neutral-300': !props['aria-invalid'],
          },
        )}
        ref={ref}
        aria-invalid={!!props['aria-invalid']}
      />
      {iconsRight && <div className="absolute right-2 top-[9px] z-10">{iconsRight()}</div>}
    </div>
  );
});

type TextFieldComponent = React.FC<
  React.PropsWithChildren<{
    className?: string;
  }>
> & {
  Label: typeof Label;
  Hint: typeof Hint;
  Input: typeof Input;
  Error: typeof ErrorMessage;
};

const TextField: TextFieldComponent = ({ children, className }) => {
  return <div className={classNames(`flex flex-col space-y-1`, className)}>{children}</div>;
};

const ErrorMessage: React.FC<{ error: Maybe<string> } & React.HTMLAttributes<unknown>> = ({ error, ...props }) => {
  const shouldDisplay = !!error;

  return (
    <Transition
      show={shouldDisplay}
      appear={shouldDisplay}
      enter="ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-50"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Hint>
        <span {...props} className={'py-0.5 text-red-700 dark:text-red-500'}>
          {error}
        </span>
      </Hint>
    </Transition>
  );
};

TextField.Hint = Hint;
TextField.Label = Label;
TextField.Input = Input;
TextField.Error = ErrorMessage;

export default TextField;
