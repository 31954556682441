import { createElement } from 'react';
import classNames from 'classnames';

type Props = React.LabelHTMLAttributes<unknown> & {
  as?: string;
};

const Label: React.FCC<Props> = ({ children, className, as, ...props }) => {
  const tag = as ?? `label`;

  return createElement(
    tag,
    {
      className: classNames(
        `w-full text-sm font-medium text-brand-primary-black dark:text-gray-400 [&>*]:mt-[0.35rem]`,
        className,
      ),
      ...props,
    },
    children,
  );
};

export const LabelPurple: React.FCC<Props> = ({ children, className, as, ...props }) => {
  const tag = as ?? `label`;

  return createElement(
    tag,
    {
      className: classNames(
        `w-full text-sm font-medium ${`text-dark-blue dark:text-white`} [&>*]:mt-[0.35rem]`,
        className,
      ),
      ...props,
    },
    children,
  );
};

export default Label;
