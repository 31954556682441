'use client';

import { useCallback } from 'react';
import Trans from '~/core/ui/Trans';

import AuthProviderButton from '~/core/ui/AuthProviderButton';
import If from '~/core/ui/If';

import AuthErrorMessage from './AuthErrorMessage';
import PageLoadingIndicator from '~/core/ui/PageLoadingIndicator';
import { sanitizeUrl } from '~/lib/utils';
import configuration from '~/configuration';
import useSignInWithProvider from '~/core/hooks/use-sign-in-with-provider';
import { useGetComponentConfig } from '@portal/branding/hooks/useGetComponentConfig';

let OAUTH_PROVIDERS = configuration.auth.providers.oAuth;

const OAuthProviders: React.FCC<{
  returnUrl?: string;
  signUp?: boolean;
}> = (props) => {
  const signInWithProviderMutation = useSignInWithProvider();
  const { data: logoConfig, isLoading: isLogoConfigLoading } = useGetComponentConfig<{
    src: string;
    height: number;
    width: number;
  }>('images.load-indicator');

  // we make the UI "busy" until the next page is fully loaded
  const loading = signInWithProviderMutation.isMutating;

  const onSignInWithProvider = useCallback(async (signInRequest: () => Promise<unknown>) => {
    try {
      const credential = await signInRequest();

      if (!credential) {
        return Promise.reject();
      }
    } catch (error) {
      throw error;
    }
  }, []);

  if (!OAUTH_PROVIDERS || !OAUTH_PROVIDERS.length) {
    return null;
  }

  return (
    <>
      <If condition={loading && !isLogoConfigLoading}>
        <PageLoadingIndicator logoProps={logoConfig?.props} />
      </If>

      <div className={'flex w-full flex-1 flex-col space-y-3'}>
        <div className={'flex-col space-y-2'}>
          {OAUTH_PROVIDERS.map((provider) => {
            return (
              <AuthProviderButton
                key={provider}
                providerId={provider}
                onClick={() => {
                  const origin = window.location.origin;
                  const signInFromLink = configuration.paths.signInFromLink;

                  const returnUrlParams = props.returnUrl ? `?redirectPath=${sanitizeUrl(props.returnUrl)}` : '';

                  const returnUrl = [signInFromLink, returnUrlParams].join('');
                  const redirectTo = configuration.site.siteUrl
                    ? [configuration.site.siteUrl, returnUrl].join('')
                    : [origin, returnUrl].join('');

                  const credentials = {
                    provider,
                    options: {
                      redirectTo,
                      scopes: 'email',
                    },
                  };

                  return onSignInWithProvider(() => signInWithProviderMutation.trigger(credentials));
                }}
              >
                <Trans
                  i18nKey={props.signUp ? 'auth:signUpWithProvider' : 'auth:signInWithProvider'}
                  values={{
                    provider: getProviderName(provider),
                  }}
                />
              </AuthProviderButton>
            );
          })}
        </div>

        <AuthErrorMessage error={signInWithProviderMutation.error} />
      </div>
    </>
  );
};

function getProviderName(providerId: string) {
  const capitalize = (value: string) => value.slice(0, 1).toUpperCase() + value.slice(1);

  if (providerId.endsWith('.com')) {
    return capitalize(providerId.split('.com')[0]);
  }

  return capitalize(providerId === 'azure' ? 'microsoft' : providerId);
}

export default OAuthProviders;
