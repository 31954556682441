import type { PropsWithChildren } from 'react';
import classNames from 'classnames';
import If from '~/core/ui/If';
import Image from 'next/image';

export default function PageLoadingIndicator({
  children,
  fullPage,
  displayLogo,
  className,
  logoProps,
}: PropsWithChildren<{
  className?: string;
  fullPage?: boolean;
  displayLogo?: boolean;
  logoProps?: { src: string; width: number; height: number };
}>) {
  const useFullPage = fullPage ?? true;
  const shouldDisplayLogo = displayLogo ?? true;

  return (
    <div
      className={classNames('flex flex-col items-center justify-center space-y-6', className, {
        [`fixed top-0 left-0 z-[100] h-screen w-screen bg-white dark:bg-black-500`]: useFullPage,
      })}
    >
      <If condition={shouldDisplayLogo}>
        <div className={'my-2'}>
          {logoProps && <Image {...logoProps} priority={true} alt="Loading indicator logo" />}
        </div>
      </If>

      {children}
    </div>
  );
}
